

.chess-piece{
    background-size: calc(var(--board_height)/8);
    background-position: center;
    background-repeat: no-repeat;
    height: var(--square_piece);
    width: var(--square_piece);
}

.chess-piece:hover{
    cursor: grab;
}
.bottomLabel{
    position:relative;
    left: 85%;
    top: 80%;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    font-size: 1rem;    
}
.bottomLabelTwo{
    position:relative;
    left: 75%;
    top: 80%;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    font-size: 1rem;  

}

@media (max-width:1200px){
    .bottomLabel, .bottomLabelTwo{
        font-size: .75rem;
    }
}