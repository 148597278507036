
.homeLink{
    width: 75%;
}

.white-home{
    position: relative;
    float: left;
    height: 100px;
    border-right: 6px solid;
    border-color: black;
    padding: 0px;


}
.black-home{
    float: right;
    border-left: 6px solid;
    border-color: black;
}
.container_homepage{
    display: grid;
    grid-template-columns: 60%;
    justify-content: center;
}
.gameListDiv:hover{
    background-color: red;
    cursor: pointer;
    border-color: white;
    color: black;
}
.gameListDiv:hover .white-home, .gameListDiv:hover .black-home{
    border-color: white;
}
.gameListDiv:hover .LINKGAME{
    color: white;
}
.gameListDiv a{
    font-weight: bold;
    font-size: 22px;
    
}
.homePlayer{
    width: 100px;
    height: 100px;

}
.LINKGAME{
    display: inline;
    font-weight: bold;
    font-size: 22px;
    color: black
}
.gameListDiv{
    height: 100px;
    text-align: center;
    vertical-align: center;
    background-color: rgba(6, 189, 189, 0.623);
    border: 4px solid;
    background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");
    padding: 0;
    border-color: black;
    margin-bottom: 2px;
}
.gitLogo{
    width: 50px;
    
    color: antiquewhite;
}
.gitLogoDiv{
    position: absolute;
    left: 15px;
    top: 9%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white

}
.gitLabel{
    right: 15px;
    font-size: .9em;
    display: none;
}
.gitLogoDiv:hover .gitLabel{
    display: flex;
}
.gitLogoDiv:hover{
    left: .4%;
}
.gitLogoDiv:hover .gitLogo{
    width: 60px;
}