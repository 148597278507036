.player{
    height: 150px;
    width: 150px;
    border: 4px solid;
}
table{
    width: 158px;
}
.game-img{
    display: flex;
    flex-direction: column;
    height: 175px;
    width: 158px;
    justify-content: center;
    text-align: center;
}
span{
    border: 4px solid;
    font-weight: bold;
    border-top: 0;
    background-color: antiquewhite;

}